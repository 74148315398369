export default function Sketch(p5) {
  let canvas

  p5.setup = () => {
    // canvas = p5.createCanvas(400, 400);
    canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight)
    // p5.saveCanvas(canvas, "fld.jpg");
  }

  p5.draw = () => {
    p5.background(40)

    for (let i = 20; i < 400; i = i + 20) {
      p5.stroke("#fff")
      p5.line(p5.width + i, p5.height, 0, 400 - i)
      p5.line(p5.width, p5.height - i, p5.height, p5.width - i)
    }
  }

  p5.windowResized = () => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight)
  }
}
