import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import BrandLogo from "../assets/brand.svg"

// Logo
const Logo = styled.div`
  width: 36px;
  height: auto;
  margin: 16px;
  fill: #fff;
  position: fixed;
  .svgClass {
    width: 36px;
    height: auto;
    fill: #fff;
    margin: 16px;
  }
`

const hero = () => (
  <div>
    <Link to="/">
      <Logo>
        <img src={BrandLogo} alt="logo" width="36"></img>
        {/* <svg className="svgClass" viewBox="0 0 272.2 313.3">
          <g>
            <path
              d="M91.4,313.3c-25.4,0-47-8.7-64.8-26.2C8.9,269.6,0,248.6,0,224s7-45.2,21-61.7s31-26.1,51.2-28.9
                c-16.5-24-24.7-45.1-24.7-63.1s7.3-34.2,21.8-48.6S103.7,0,129.2,0s45.6,6.7,60.4,20.1c14.8,13.4,22.2,29.2,22.2,47.4
                c0,9.2-1.7,17.2-5,23.9h-56.6c2.5-4.5,3.8-10.1,3.8-17s-2.4-12.6-7-17.4c-4.6-4.7-10.6-7.1-17.8-7.1c-7.3,0-13.1,2.4-17.6,7.1
                s-6.7,11-6.7,18.7s4.2,17.1,12.6,28.3l154.8,206.4h-68.8l-32.3-44.5c-7.3,14-18.1,25.4-32.5,34.2
                C124.2,308.9,108.5,313.3,91.4,313.3z M62.5,252.3c8.1,8.5,17.9,12.8,29.4,12.8s21.3-4.3,29.6-12.8c8.2-8.5,12.4-18.8,12.4-30.8
                s-4.1-22.2-12.4-30.4s-18.1-12.4-29.6-12.4s-21.2,4.1-29.4,12.4c-8.1,8.3-12.2,18.4-12.2,30.4C50.3,233.4,54.4,243.7,62.5,252.3z"
            />
          </g>
        </svg> */}
      </Logo>
    </Link>
  </div>
)

export default hero
